import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NgControl,
  NgModel,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { GeorgianDatePipe } from '@shared/pipes/georgian-date.pipe';
import { GeorgianDateTimeUiPipe } from '@shared/pipes/dates/georgian-date-time-ui.pipe';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrl: 'form-error.component.scss',
  standalone: true,
  imports: [TranslateModule, GeorgianDatePipe, GeorgianDateTimeUiPipe],
})
export class FormErrorComponent {
  @Input() control?: AbstractControl | NgControl | NgModel | FormControl;
}
